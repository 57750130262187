// Skills Icons
import htmlIcon from "./images/html.svg"
import cssIcon from "./images/css.svg"
import reactIcon from "./images/react.svg"
import jsIcon from "./images/javascript.svg"
import designIcon from "./images/design.svg"
import codeIcon from "./images/code.svg"

// Social Icon
import githubIcon from "./images/github.svg"
import codepenIcon from "./images/codepen.svg"
import dribbbleIcon from "./images/dribbble.svg"
import instagramIcon from "./images/instagram.svg"

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "Kevin",
  headerTagline: [
    //Line 1 For Header
    "Building digital",
    //Line 2 For Header
    "products, brands,",
    //Line 3 For Header
    "and experiences",
  ],
  //   Header Paragraph
  headerParagraph:
    "I am a multidisciplinary designer, developer, & creative strategist open to new opportunities and projects.",

  //Contact Email
  contactEmail: "kvin.stphns@gmail.com",

  // End Header Details -----------------------


  // Work Section ------------------------
  work: [
    {
      title: "UX/UI", //Project Title - Add Your Project Title Here
      para:
        "A collection of design projects. Design is my strong suite and I have done work for a variety of companies and clients.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1522542550221-31fd19575a2d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2370&q=80",
      //Project URL - Add Your Project Url Here
      url: "https://kevinstephens.notion.site/kevinstephens/Portfolio-747b27d3451a4bbe99d4ca8b7e25aa32?p=1243a386de4141b2b73690833ca8b51b&pm=c",
    },
    {
      title: "WEBSITES", //Project Title - Add Your Project Title Here
      para:
        "A few examples of websites that I have designed from the ground up and deployed", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://freight.cargo.site/t/original/i/c14000defeb65dd4b881b2d4b64ff3233f9f09510d8ffea2fcf69f1b119d0a59/Screen-Shot-2022-03-18-at-4.53.59-PM.png",
      //Project URL - Add Your Project Url Here
      url: "https://kevinstephens.notion.site/85bfe0f7d0d0415d8e1a8ba20bdd7b42?v=a93479f89e0d4ab899391fb1560db471",
    },
    {
      title: "ADVERTISEMENTS", //Project Title - Add Your Project Title Here
      para:
        "A collection of advertisments I designed for various projects, companies, or clients.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://freight.cargo.site/w/800/q/75/i/49bc7b3e8fe3ea016a63949a0439c8a525d20bcb27ab2908c2bf8f48c3c198ec/titos.jpg",
      //Project URL - Add Your Project Url Here
      url: "https://kevinstephens.notion.site/6167edff0b5748b599bddf23adcedbfa?v=f3528cc6d2834fbbafdff9a1d0ae55d6",
    },
    

    /*
    If You Want To Add More Project just Copy and Paste This At The End
    ,{
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Work Section -----------------------


  // Project Section ------------------------
  projects: [
    {
      title: "ALKBLN", //Project Title - Add Your Project Title Here
      para:
        "The world’s first full-stack creative-directory hosting exclusively black talent, products and services.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://freight.cargo.site/w/800/q/75/i/a6ac1c492fa9f41c9a22151fe8e6dee326db47ddd8c7885dcc2d29336641a122/Stephens_Kevin-1-1.png",
      //Project URL - Add Your Project Url Here
      url: "https://kevinstephens.notion.site/kevinstephens/Portfolio-747b27d3451a4bbe99d4ca8b7e25aa32?p=1243a386de4141b2b73690833ca8b51b&pm=c",
    },
    {
      title: "Nowhere", //Project Title - Add Your Project Title Here
      para:
        "An international black boutique service as a discovery and commercial platform hosting exclusively black brands & products through digital and physical distribution channels.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://freight.cargo.site/w/900/q/75/i/a91cc24ab1b7be78781994880b725f2810664219c8a4ae86cecd78e700418e18/Trailer_pop-up_Final_2_2020-Apr-30_02-31-19AM-000_CustomizedView34922190955.png",
      //Project URL - Add Your Project Url Here
      url: "https://kevinstephens.notion.site/kevinstephens/Portfolio-747b27d3451a4bbe99d4ca8b7e25aa32?p=2883aa398ef945e196523dd5b6d18ed7&pm=c",
    },
    {
      title: "Covid-19 Care Package", //Project Title - Add Your Project Title Here
      para:
        "Creative care package providing creative resources to remote designers affected by the pandemic.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://freight.cargo.site/w/400/q/75/i/750d351bd0f6ad1fec04ab5484e952e47063f17413454f390bcb14a51ff12233/Screen-Shot-2021-03-08-at-2.55.29-AM.png",
      //Project URL - Add Your Project Url Here
      url: "https://kevinstephens.notion.site/kevinstephens/Portfolio-747b27d3451a4bbe99d4ca8b7e25aa32?p=5c46bf23bf104090992698fb0fbf2d76&pm=c",
    },
    

    /*
    If You Want To Add More Project just Copy and Paste This At The End
    ,{
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Projects Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "Design is my strongest passion & it’s what I live and breathe every day. I have made significant strides in developing my skills, educating myself about design thinking, brand identity, and visual storytelling. On top of that, I built proficiency in various design tools, techniques and consulting, which plays an essential role in my professional development. My specialties include design strategy as well as visual graphics, logos, and websites using Adobe’s design software suite and other various design tools.",
  aboutParaTwo:
    "I am a Parsons School of Design Alumni where I majored in Strategic Design & Management. My program allowed me to hone my design skills in creative strategy and business, providing vital structure and support. It was there I improved my collaborative competency through navigating team projects while gaining a new perspective on the nuances involved in the field.",
  aboutParaThree:
    "My last few months have been incredibly eye-opening, exploring educational avenues within my field, while soaking up tons of valuable information during this pandemic. I'll make a great addition to your team. Hope to connect soon!",
  aboutImage:
    "https://i.ibb.co/dGMP2nM/moji.png",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      img: htmlIcon,
      para:
        "I have experience with HTML5. I have worked on a variety of projects utilizing bootstrap to create landing pages and multipage websites.",
    },
    {
      img: cssIcon,
      para:
        "I have experience with CSS & SCSS. I have style many projects utilizing flex and grid systems for layout as well and I’m proficient with color and typography.",
    },
    {
      img: jsIcon,
      para:
        "I have experience with vanilla Javascript as well as more advanced Javascript libraries. Many of the projects I’ve worked on use Javascript.",
    },
    {
      img: reactIcon,
      para:
        "I have experience with React using Create-React-app as well as the GatsbyJS framework. I focus on using React for most of my front-end projects.",
    },
    {
      img: designIcon,
      para:
        "Design is my specialty! I use a variety of Design tools like Figma and Adobe for prototyping and graphics. Other skills include design strategy and design research.",
    },
    {
      img: codeIcon,
      para:
        "Building my development skills! I am learning a variety of languages and frameworks in a full-stack development bootcamp.",
    },
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  promotionHeading: "Heading",
  promotionPara:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Open to full-time opportunites & freelance projects. Let's connect!",
  social: [
    // Add Or Remove The Link Accordingly
    { img: githubIcon, url: "https://github.com/kvin-stphns" },
    {
      img: codepenIcon,
      url: "https://www.codepen.com/kvin-stphns",
    },
    {
      img: dribbbleIcon,
      url: "https://dribbble.com/kevinstephens",
    },
    {
      img: instagramIcon,
      url: "https://www.instagram.com/cozykev",
    },
  ],

  // End Contact Section ---------------
}

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
